import type { InputStoryblok } from "~/types/storyblok";
import type { Option } from "@kippie/ui/src/runtime/components";
import type { KippieBusinessHour } from "@shopware-pwa/types";

export function generateForm(inputs: InputStoryblok[]): { [k: string]: any } {
	const obj: { [k: string]: any } = {};

	inputs.forEach((input) => {
		obj[input.name as keyof { [k: string]: any }] = null;
	});

	return obj;
}

export function generateOpeningHours(businessHours: KippieBusinessHour[]): Option[] {
	const days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];
	const openingHours: Option[] = [];

	businessHours.forEach((day: KippieBusinessHour, index: number) => {
		openingHours.push({
			label: days[index],
			value:
				!day?.openTime || !day?.closeTime
					? `Gesloten`
					: `${day.openTime.slice(0, 5)} - ${day.closeTime.slice(0, 5)}`
		});
	});

	return openingHours;
}
